import tinycolor from 'tinycolor2';

const MAX_NODES = 1000;
const NODE_ANGLE = 0.35;
const NODE_MIN_DIFF = 0.1;
const BRANCH_FACTOR = 4;
const MAX_LEVEL = 3;

const canvas = document.getElementById('canvas');
const ctx = canvas.getContext('2d');

canvas.width = document.body.clientWidth;
canvas.height = document.body.clientHeight;

let sizeFactor = Math.min(canvas.width, canvas.height);

class Node {
	start = {
		x : 0,
		rx: 0,
		y : 0,
		ry: 0,
	};

	dx = 0;
	dy = 0;

	duration = 1;
	maxLength = 0;
	maxWidth = 0;
	length = 0;
	lengthStep = 0;
	end = null;
	angle = 0;

	steps = 0;
	step = 0;
	stepLength = 0;
	level = 0;
	depth = 0;

	constructor(level = 0, depth = 0, x = 0, y = 0, angle = 0, duration = 1, maxLength = 0.2, maxWidth = 10, steps = 3) {
		this.level = level;
		this.depth = depth;
		this.start.x = x;
		this.start.y = y;
		this.angle = angle;

		this.dx = Math.cos(angle);
		this.dy = Math.sin(angle);
		this.maxLength = maxLength;
		this.maxWidth = maxWidth;
		this.duration = duration;
		this.steps = steps;
		this.step = 0;
		this.stepLength = this.maxLength / steps;
		this.lengthStep = this.maxLength / duration;
		this.resizeToCanvas();
	}

	resizeToCanvas() {
		this.start.rx = this.start.x * sizeFactor;
		this.start.ry = this.start.y * sizeFactor;

		if (this.end) {
			this.end.rx = this.end.x * sizeFactor;
			this.end.ry = this.end.y * sizeFactor;
		}
	}
}

const nodes = {
	node: new Node(0, 0, 0.5, 1, - 0.5 * Math.PI, 0.5, 0.2, 10),
	branches: [],
}

document.body.onresize = () => {
	canvas.width = document.body.clientWidth;
	canvas.height = document.body.clientHeight;
	sizeFactor = Math.min(canvas.width, canvas.height);
	resizeNodes(nodes);
}

function newAngle(angle, isBranch) {
	const odd = Math.random() > 0.5 ? 1 : -1;
	const bf = isBranch ? BRANCH_FACTOR : 1;

	return odd * (NODE_MIN_DIFF + Math.random() * bf * NODE_ANGLE);
}

let nodeCount = 0;
function updateNode(branch, dt) {
	const node = branch.node;
	if (node.end) {
		branch.branches.forEach(n => updateNode(n, dt));
		return;
	}

	node.length += node.lengthStep * dt;

	if (node.length > node.maxLength) {
		node.length = node.maxLength;
		node.end = {
			x: node.start.x + node.dx * node.length,
			y: node.start.y + node.dy * node.length,
			rx: 0,
			ry: 0,
		}
		
		node.resizeToCanvas();

		if (node.level < MAX_LEVEL && nodeCount < MAX_NODES) {
			const branches = 1 + Math.floor(Math.random());

			const newBase = new Node(
				node.level,
				node.depth + 1,
				node.end.x,
				node.end.y,
				node.angle + newAngle(node.angle, false),
				node.duration * 1.2,
				node.maxLength * 0.8,
				node.maxWidth * 0.9,
			);

			branch.branches.push({
				node: newBase,
				branches: [],
			});
			nodeCount++;

			const branchAngle = newAngle(newBase.angle, true);
			for (let i = 0; i < branches; i++) {
				const odd = i % 2 ? 1 : -1;
				branch.branches.push({
					node: new Node(
						node.level + 1,
						node.depth + 1,
						node.end.x,
						node.end.y,
						newBase.angle + branchAngle * odd,
						node.duration * 1.5,
						node.maxLength * 0.4,
						node.maxWidth * 0.4,
					),
					branches: [],
				});
				nodeCount++;
			}
		}
	}

	if (node.level < MAX_LEVEL && nodeCount < MAX_NODES) {
		let oldStep = node.step;
		node.step = node.length / node.stepLength | 0;
		if (oldStep != node.step) {
			const newBase = new Node(
				node.level + 1,
				node.depth + 1,
				node.start.x + node.dx * node.length,
				node.start.y + node.dy * node.length,
				node.angle + newAngle(node.angle, true),
				node.duration * 1.5,
				node.maxLength * 0.4,
				node.maxWidth * 0.4,
			);

			branch.branches.push({
				node: newBase,
				branches: [],
			});
			nodeCount++;
		}
	}

	branch.branches.forEach(n => updateNode(n, dt));
}

const PI2 = Math.PI * 2;
function drawCircle(x, y, radius) {
	ctx.beginPath();
	ctx.arc(x, y, radius, 0, PI2);
}

function renderNode(branch) {
	const node = branch.node;
	if (!node) return;

	branch.branches.forEach(n => renderNode(n));

	const color = tinycolor("#3b65de").desaturate(node.depth * 12);
	ctx.strokeStyle = '#' + color.toHex();

	if (node.end) {
		ctx.lineWidth = node.maxWidth;
		
		ctx.beginPath();
		ctx.moveTo(node.start.rx, node.start.ry);
		ctx.lineTo(node.end.rx, node.end.ry);
		ctx.stroke();
	} else {
		ctx.lineWidth = node.maxWidth * node.length / node.maxLength;

		const endX = node.start.rx + node.dx * node.length * sizeFactor;
		const endY = node.start.ry + node.dy * node.length * sizeFactor;
		ctx.beginPath();
		ctx.moveTo(node.start.rx, node.start.ry);
		ctx.lineTo(
			endX,
			endY,
		);
		ctx.stroke();
	}
}

function resizeNodes(branch) {
	const node = branch.node;
	node.resizeToCanvas();
	branch.branches.forEach(n => resizeNodes(n));
}

let lastTime = 0;
function step(time) {
	if (lastTime == 0)
		lastTime = time;
	let dt = (time - lastTime) / 1000;
	lastTime = time;

	// UPDATE
	updateNode(nodes, dt);

	// RENDER
	canvas.width = canvas.width;
	ctx.lineCap = "round";
	ctx.save();
	ctx.translate(canvas.width - sizeFactor, canvas.height - sizeFactor);
	renderNode(nodes);

	window.requestAnimationFrame(step);
}

window.requestAnimationFrame(step);